import React, { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"
import WebMidi from "webmidi"
import Layout from "../../components/Layout"
import {
  assembleSampler,
  triggerAttack,
  triggerRelease,
} from "../../music/Sampler"

const Midi = ({}) => {
  const [keysPressed, setKeyPressed] = useState(new Set([]));
  const trigger = e => {
    triggerAttack(e.note.name + e.note.octave)
    setKeyPressed(new Set([...keysPressed.add(e.note.name + e.note.octave)]));
  }
  const release = e => {
    triggerRelease(e.note.name + e.note.octave)
    keysPressed.delete(e.note.name + e.note.octave);
    setKeyPressed(new Set([...keysPressed]));
  }
  useEffect(()=> {
    WebMidi.enable(function (err) {
      if (err) {
        console.log("WebMidi could not be enabled.", err)
      } else {
        console.log("WebMidi enabled!")
        console.log(WebMidi.inputs)
        var input = WebMidi.getInputByName("MPK mini 3")

        input.addListener("noteon", "all", function (e) {
          trigger(e)
        })
        input.addListener("noteoff", "all", function (e) {
          release(e)
        })
      }
    })
    
  })
  useEffect(() => {
    assembleSampler()
  }, [])

  return (
    <Layout>
      <h1 className="text-xl text-center text-teal-800 mb-2"><i class="las la-keyboard mr-1"></i>Midi Test Page.</h1>
      <div className="grid grid-cols-8 gap-4 text-teal-800">
        {[...keysPressed].map(key => {
            return (
              <div key={key} className="bg-teal-400  h-32 rounded flex items-center justify-center">
                <p className="text-xl">{key}</p>
              </div>
            )
        })}
        {[...keysPressed].length ===0 && <div className="col-span-8 bg-blue-100  h-32 rounded flex flex-col items-center justify-center text-center">
                <p className="text-sm text-blue-800">"We are the music makers, and we are the dreamers of the dreams."</p>
                <p className="text-3xl text-blue-800">Play something!</p>
              </div>}
      </div>
    </Layout>
  )
}

const mapStateToProps = ({}) => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

const ConnectedMidi =
  typeof window !== `undefined`
    ? connect(mapStateToProps, mapDispatchToProps)(Midi)
    : Midi

export default ConnectedMidi
